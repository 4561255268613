<template>
  <Layout>
    <EditTimeModal
      v-if="showEditTimeModal"
      :timeId="timeIdSelected"
      @closeModal="closeEditTimesModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Times inscritos</h5>
      </div>
      <div class="card-body">
        <ListTimes :times="times" @editTime="EditTime" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import EditTimeModal from '@/components/administration/times/EditTimeModal.vue'
import ListTimes from '../../../components/administration/times/ListTimes.vue'

export default {
  name: 'Times',

  components: {
    Layout,
    EditTimeModal,
    LoadingData,
    ListTimes,
  },

  data() {
    return {
      title: 'Times',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Times',
          active: true,
        },
      ],
      loadData: true,
      showEditTimeModal: false,
      times: null,
      timeIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getTimes() {
      try {
        this.times = await this.$store.dispatch('getTimes')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    EditTime(timeId) {
      this.timeIdSelected = timeId || null
      this.showEditTimeModal = true
    },

    closeEditTimeModal(loadListTimes) {
      if (loadListTimes) {
        this.getTimes()
      }
      this.showEditTimeModal = false
    },
  },

  mounted() {
    this.getTimes()
  },
}
</script>
