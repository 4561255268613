<template>
  <b-modal
    id="modalEditTime"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <!-- NOME DO TIME -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome do time: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.nomeTime.$error }"
            placeholder="Nome fantasia"
            v-model="$v.nomeTime.$model"
          />
          <span v-if="!$v.nomeTime.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.nomeTime.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos 3 caracteres
          </span>
        </div>
      </div>
      <!-- NOME E DATA DENASCIMENTO -->
      <div v-for="(v, index) in $v.jogador.$each.$iter" :key="index">
        <h5 v-if="index == 0" class="card-title mb-0">
          Jogador {{ parseInt(index) + 1 }} (Responsável pelo time)
        </h5>
        <h5 v-else class="card-title mb-0">
          Jogador {{ parseInt(index) + 1 }}
        </h5>
        <br />
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>Nome: <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.nome.$error }"
              placeholder="Nome Completo"
              v-model.trim="v.nome.$model"
            />
            <span v-if="!v.nome.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="!v.nome.minLength" class="invalid-feedback">
              O nome deve possuir pelo menos 8 caracteres
            </span>
          </div>
          <div class="form-group col-sm-4">
            <label
              >Data de nascimento: <span class="text-danger">*</span></label
            >
            <input
              type="date"
              class="form-control"
              :class="{ 'is-invalid': v.data_nascimento.$error }"
              placeholder="Data de nascimento"
              v-model="v.data_nascimento.$model"
            />
            <span v-if="!v.data_nascimento.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group col-sm-2">
            <label>Gênero: <span class="text-danger">*</span></label>
            <select
              class="form-control"
              :class="{ 'is-invalid': v.sexo.$error }"
              v-model="v.sexo.$model"
            >
              <option value="">Selecione o sexo</option>
              <option value="1">Feminino</option>
              <option value="2">Masculino</option>
              <option value="3">Outro</option>
              <option value="4">Não desejo informar</option>
            </select>
            <span v-if="!v.sexo.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>
        <!-- CPF E RG -->
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>CPF: <span class="text-danger">*</span></label>
            <the-mask
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.cpf.$error }"
              placeholder="000.000.000-00"
              v-model="v.cpf.$model"
              :mask="'###.###.###-##'"
              :masked="true"
            />
            <span v-if="!v.cpf.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group col-sm-6">
            <label>RG: <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.rg.$error }"
              placeholder="Nº do RG"
              v-model="v.rg.$model"
            />
            <span v-if="!v.rg.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>

        <!-- EMAIL e CELULAR -->
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>E-mail: <span class="text-danger">*</span></label>
            <input
              type="email"
              class="form-control"
              :class="{ 'is-invalid': v.email.$error }"
              v-model="v.email.$model"
              placeholder="usuario@email.com.br"
            />
            <span v-if="!v.email.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="!v.email.email" class="invalid-feedback">
              Formato de e-mail inválido
            </span>
          </div>
          <div class="form-group col-sm-6">
            <label>Celular:</label>
            <the-mask
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.celular.$error }"
              placeholder="(00) 98888-8888"
              v-model="v.celular.$model"
              :mask="['(##) ####-####', '(##) #####-####']"
              :masked="true"
            />
            <span v-if="!v.celular.minLength" class="invalid-feedback">
              Formato de telefone inválido <br />
              Ex: (00) 98888-8888
            </span>
          </div>
        </div>
        <hr />
        <br />
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'EditTimeModal',

  components: {
    TheMask,
  },

  props: {
    timeId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      time: {
        nomeTime: '',
        city_state_id: '',
        descricao: '',
        telefone: '',
        endereco: '',
        num_endereco: '',
        bairro: '',
        cep: '',
        complemento: '',
      },
      disableButton: false,
      states: null,
      cities: null,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.lojaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'loja.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.loja.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.loja.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getLoja() {
      try {
        this.loadDataEditing = true
        this.loja = await this.$store.dispatch('getLoja', this.lojaId)
        this.$bvModal.show('modalCreateEditLoja')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.lojaId) {
            console.log(this.loja)
            response = await this.$store.dispatch('updateLoja', this.loja)
          } else {
            response = await this.$store.dispatch('saveLoja', this.loja)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                toaster: 'b-toaster-top-center',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    loja: {
      descricao: {
        required,
        minLength: minLength(8),
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      city_state_id: { required },
      city_id: { required },
      endereco: { required },
      num_endereco: { required },
      bairro: { required },
      cep: { required },
    },
  },

  mounted() {
    this.getStates()
    if (this.lojaId) {
      this.getLoja()
    } else {
      this.$bvModal.show('modalCreateEditLoja')
    }
  },
}
</script>
