<template>
  <div class="text-center">
    <span aria-hidden="true" class="spinner-border" />
    <p>Carregando...</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
