<template>
  <div>
    <form @submit.prevent="save">
      <!-- NOME DO TIME-->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome do time: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.nomeTime.$error }"
            placeholder="Nome do time para a competição"
            v-model="$v.nomeTime.$model"
          />
          <span v-if="!$v.nomeTime.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.nomeTime.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos 8 caracteres
          </span>
        </div>
      </div>
      <hr />
      <!-- NOME E DATA DENASCIMENTO -->
      <div v-for="(v, index) in $v.jogador.$each.$iter" :key="index">
        <h5 v-if="index == 0" class="card-title mb-0">
          Jogador {{ parseInt(index) + 1 }} (Responsável pelo time)
        </h5>
        <h5 v-else class="card-title mb-0">
          Jogador {{ parseInt(index) + 1 }}
        </h5>
        <br />
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>Nome: <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.nome.$error }"
              placeholder="Nome Completo"
              v-model.trim="v.nome.$model"
            />
            <span v-if="!v.nome.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="!v.nome.minLength" class="invalid-feedback">
              O nome deve possuir pelo menos 8 caracteres
            </span>
          </div>
          <div class="form-group col-sm-4">
            <label
              >Data de nascimento: <span class="text-danger">*</span></label
            >
            <input
              type="date"
              class="form-control"
              :class="{ 'is-invalid': v.data_nascimento.$error }"
              placeholder="Data de nascimento"
              v-model="v.data_nascimento.$model"
            />
            <span v-if="!v.data_nascimento.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group col-sm-2">
            <label>Gênero: <span class="text-danger">*</span></label>
            <select
              class="form-control"
              :class="{ 'is-invalid': v.sexo.$error }"
              v-model="v.sexo.$model"
            >
              <option value="">Selecione o sexo</option>
              <option value="1">Feminino</option>
              <option value="2">Masculino</option>
              <option value="3">Outro</option>
              <option value="4">Não desejo informar</option>
            </select>
            <span v-if="!v.sexo.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>
        <!-- CPF E RG -->
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>CPF: <span class="text-danger">*</span></label>
            <the-mask
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.cpf.$error }"
              placeholder="000.000.000-00"
              v-model="v.cpf.$model"
              :mask="'###.###.###-##'"
              :masked="true"
            />
            <span v-if="!v.cpf.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group col-sm-6">
            <label>RG: <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.rg.$error }"
              placeholder="Nº do RG"
              v-model="v.rg.$model"
            />
            <span v-if="!v.rg.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>

        <!-- EMAIL e CELULAR -->
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>E-mail: <span class="text-danger">*</span></label>
            <input
              type="email"
              class="form-control"
              :class="{ 'is-invalid': v.email.$error }"
              v-model="v.email.$model"
              placeholder="usuario@email.com.br"
            />
            <span v-if="!v.email.required" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="!v.email.email" class="invalid-feedback">
              Formato de e-mail inválido
            </span>
          </div>
          <div class="form-group col-sm-6">
            <label>Celular:</label>
            <the-mask
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v.celular.$error }"
              placeholder="(00) 98888-8888"
              v-model="v.celular.$model"
              :mask="['(##) ####-####', '(##) #####-####']"
              :masked="true"
            />
            <span v-if="!v.celular.minLength" class="invalid-feedback">
              Formato de telefone inválido <br />
              Ex: (00) 98888-8888
            </span>
          </div>
        </div>
        <hr />
        <br />
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-success"
          title="Cadastrar"
          :disabled="$v.$invalid || disableButton"
        >
          Cadastrar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'InscricaoTime',

  components: {
    TheMask,
  },

  data() {
    return {
      nomeTime: '',
      jogador: [
        {
          nome: '',
          sexo: '',
          data_nascimento: '',
          cpf: '',
          rg: '',
          email: '',
          celular: '',
        },
        {
          nome: '',
          sexo: '',
          data_nascimento: '',
          cpf: '',
          rg: '',
          email: '',
          celular: '',
        },
        {
          nome: '',
          sexo: '',
          data_nascimento: '',
          cpf: '',
          rg: '',
          email: '',
          celular: '',
        },
        {
          nome: '',
          sexo: '',
          data_nascimento: '',
          cpf: '',
          rg: '',
          email: '',
          celular: '',
        },
      ],
      disableButton: false,
    }
  },

  methods: {
    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('saveTime', {
            nomeTime: this.nomeTime,
            jogador: { jogador: this.jogador },
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true,
          })
          this.$router.push({ name: 'Home' })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                toaster: 'b-toaster-top-center',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    nomeTime: {
      required,
      minLength: minLength(8),
    },
    jogador: {
      $each: {
        nome: {
          required,
          minLength: minLength(8),
        },
        email: {
          required,
          email,
        },
        celular: {
          minLength: minLength(14),
          maxLength: maxLength(15),
        },
        data_nascimento: {
          required,
        },
        cpf: {
          required,
        },
        sexo: {
          required,
        },
        rg: {
          required,
        },
      },
    },
  },
}
</script>
